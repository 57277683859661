/* Commun */
.BlocFooter {
  display: flex;
  width: 100%;
  background-color: var(--main-color);
  box-sizing: border-box;
}

.BlocFooter div:nth-child(2) {
  display: flex;
  justify-content: center;
}

.BlocFooter div:nth-child(3) {
  display: flex;
  justify-content: flex-end;
}

.BlocFooter p {
  font-size: 16px;
}

.BlocFooter .SelectLanguage {
  display: flex;
  justify-content: flex-end;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .BlocFooter {
    padding: 1em 2em;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .BlocFooter {
    width: 100%;
  }

  .BlocFooter div:nth-child(1) {
    order: 2;
  }

  .BlocFooter div:nth-child(2) {
    order: 1;
  }

  .BlocFooter div:nth-child(3) {
    justify-content: flex-end;
    align-items: flex-end;
    order: 3;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .BlocFooter {
    padding: 1em 2em;
  }

  .BlocFooter div {
    width: calc(100% / 3);
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .BlocFooter {
    padding: 1em 2em;
  }

  .BlocFooter div {
    width: calc(100% / 3);
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .BlocFooter {
    padding: 1em 2em;
  }

  .BlocFooter div {
    width: calc(100% / 3);
  }
}