/* Commun */
.Bloc {
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}

.Bloc.main {
  background-color: var(--main-color);
}

.Bloc.secondary {
  color: white;
  background-color: var(--secondary-color);
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .Bloc {
    padding: 2em 5%;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .Bloc {
    padding: 2em 5%;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .Bloc {
    padding: 2em 10%;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .Bloc {
    padding: 3em 20%;
  }
}