.Map .Address {
  font-weight: bold;
  color: black;
}

.Map .Address p {
  font-size: 12px;
}

/* Commun */


/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {

}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {

}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {

}

/* Écran PC */
@media (min-width: 1400px) {

}