/* Commun */
.ShutterProject {
  position: fixed;
  top: 0px;
  right: 0;
  height: 100%;

  background-color: var(--main-color);
  box-shadow: -0.3em 0 1em rgba(91, 91, 91, 0.2);

  transition: .4s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 10;
}

.ShutterProject.close {
  transform: translateX(100%);
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .ShutterProject{
    width: 95%;
  }

  .ShutterProject .Form {
    padding-top: 20%;
    width: 80%;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .ShutterProject{
    padding-top: 4em;
    width: 90%;
  }

  .ShutterProject .Form {
    width: 70%;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .ShutterProject{
    padding-top: 4em;
    width: 70%;
  }

  .ShutterProject .Form {
    width: 80%;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .ShutterProject{
    padding-top: 2em;
    width: 40%;
  }

  .ShutterProject .Form {
    width: 70%;
  }
}
