/* Commun */
.BlocServices {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BlocServices .services {
  display: flex;
  flex-direction: column;
}

.BlocServices .services ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  text-align: start;
}

.BlocServices .pictures img {
  object-fit: cover;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .BlocServices {
    gap: 2em;
    padding: 0 5%;
    flex-direction: column;
  }
  
  .BlocServices .services {
    gap: 2em;
  }

  .BlocServices .pictures {
    gap: 2em;
  }
  
  .BlocServices .pictures img {
    height: 200px;
    width: 380px;
  }
  
  .BlocServices > div.pictures > div:nth-child(2) {
    padding-top: 8em;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .BlocServices {
    gap: 2em;
    padding: 0 5%;
    flex-direction: column;
  }
  
  .BlocServices .services {
    gap: 2em;
  }

  .BlocServices .pictures {
    gap: 2em;
  }
  
  .BlocServices .pictures img {
    height: 200px;
    width: 380px;
  }
  
  .BlocServices > div.pictures > div:nth-child(2) {
    padding-top: 8em;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .BlocServices {
    gap: 4em;
    padding: 0 5%;
  }
  
  .BlocServices .services {
    gap: 3em;
  }

  .BlocServices .pictures {
    gap: 2em;
  }
  
  .BlocServices .pictures img {
    width: 500px;
    height: 300px;
  }
  
  .BlocServices > div.pictures > div:nth-child(2) {
    padding-top: 8em;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .BlocServices {
    gap: 4em;
    padding: 0 5%;
  }
  
  .BlocServices .services {
    gap: 3em;
  }

  .BlocServices .pictures {
    gap: 2em;
  }
  
  .BlocServices .pictures img {
    width: 500px;
    height: 300px;
  }
  
  .BlocServices > div.pictures > div:nth-child(2) {
    padding-top: 8em;
  }
}