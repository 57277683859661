/* Commun */
.BlocTrust {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 80%;
}

.BlocTrust h2 {
  margin-bottom: 2em;
}

.BlocTrust .Companies {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8em;
  text-align: center;
}

.BlocTrust .Companies img {
  object-fit: contain;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .BlocTrust h2 {
    margin-bottom: 2em;
  }
  
  .BlocTrust .Companies {
    gap: 1em 1em;
  }
  
  .BlocTrust .Companies img { 
    height: 50px;
  }

  .BlocTrust .Companies div {
    width: 180px;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .BlocTrust h2 {
    margin-bottom: 1em;
  }
  
  .BlocTrust .Companies {
    gap: 2em;
  }
  
  .BlocTrust .Companies img {
    width: 140px;
    height: 140px;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .BlocTrust h2 {
    margin-bottom: 1em;
  }
  
  .BlocTrust .Companies {
    gap: 8em;
  }
  
  .BlocTrust .Companies img {
    width: 180px;
    height: 140px;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .BlocTrust h2 {
    margin-bottom: 2em;
  }
  
  .BlocTrust .Companies {
    gap: 8em;
  }
  
  .BlocTrust .Companies img {
    width: 200px;
    height: 150px;
  }
}