/* Commun */
.BlocPresentation {
  display: flex;
}

.BlocPresentation .pictures {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.BlocPresentation .pictures div img{
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.BlocPresentation .pictures div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BlocPresentation .description {
  width: auto;
  display: flex;
  flex-direction: column;
}

.BlocPresentation img {
  border: solid 1px var(--main-color);
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .BlocPresentation {
    gap: 2.5em;
    flex-direction: column;
    margin-top: 3em;
    margin: 0 10%;
  }
  
  .BlocPresentation .pictures {
    gap: 3em;
    justify-content: center;
  }

  .BlocPresentation .pictures div{
    width: 120px;
    height: 120px;
  }

  .BlocPresentation .description {
    gap: 2em;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .BlocPresentation {
    gap: 2.5em;
    flex-direction: column;
    margin-top: 3em;
    margin: 0 10%;
  }
  
  .BlocPresentation .pictures {
    gap: 3em;
    justify-content: center;
  }

  .BlocPresentation .pictures div{
    width: 180px;
    height: 180px;
  }

  .BlocPresentation .description {
    gap: 2em;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .BlocPresentation {
    gap: 5em;
  }
  
  .BlocPresentation .pictures {
    gap: 3em;
    width: 70%;
  }

  .BlocPresentation .pictures div{
    width: 180px;
    height: 180px;
  }

  .BlocPresentation .description {
    gap: 2em;
    width: 50%;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .BlocPresentation {
    gap: 5em;
  }
  
  .BlocPresentation .pictures {
    gap: 3em;
    width: 50%;
  }

  .BlocPresentation .pictures div{
    width: 200px;
    height: 200px;
  }

  .BlocPresentation .description {
    gap: 2em;
    width: 50%;
  }
}
