/* Commun */
.Form {
  height: 100%;
  width: 100%;
  transition: 0.4s;
}

/* Div Form */
.Form .div_form {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.Form .div_form .InputList {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.Form .div_form .InputList .Raw_1 {
  display: flex;
  flex-direction: row;
}

.Form .div_form .InputList .Other {
  display: flex;
  flex-direction: column;
}

.Form .div_form .InputList .Other .Input input {
  width: 100%;
}

.Form .div_form .SubmitForm {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

/* Div Confirmation Email */
.Form .div_confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(100vh);
  transition: 0.4s;
}

.Form .div_confirmation div {
  text-align: center;
}

/* After sending email */
.Form .div_form.mailSent {
  transform: translateY(-100vh);
}

.Form .div_confirmation.mailSent {
  transform: translateY(-60vh);
}


.Form .div_form h2 {
  margin-bottom: 2em;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  /* Div Form */
  .Form .div_form {
    height: 100%;
  }

  .Form .div_form .InputList {
    gap: 1em;
  }

  .Form .div_form .InputList .Raw_1 {
    gap: 1em;
  }

  .Form .div_form .InputList .Other {
    gap: 1em;
  }

  .Form .div_form .SubmitForm {
    margin-right: 1em;
    margin-bottom: 1em;
  }

  /* Div Confirmation Email */
  .Form .div_confirmation {
    gap: 3em;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  /* Div Form */
  .Form .div_form {
    height: 100%;
  }

  .Form .div_form .InputList {
    gap: 2em;
  }

  .Form .div_form .InputList .Raw_1 {
    gap: 4em;
  }

  .Form .div_form .InputList .Other {
    gap: 2em;
  }

  .Form .div_form .SubmitForm {
    margin-right: 1em;
    margin-bottom: 3em;
  }

  /* Div Confirmation Email */
  .Form .div_confirmation {
    gap: 3em;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  /* Div Form */
  .Form .div_form {
    height: 100%;
  }

  .Form .div_form .InputList {
    gap: 2em;
  }

  .Form .div_form .InputList .Raw_1 {
    gap: 4em;
  }

  .Form .div_form .InputList .Other {
    gap: 2em;
  }

  .Form .div_form .SubmitForm {
    margin-right: 1em;
    margin-bottom: 3em;
  }

  /* Div Confirmation Email */
  .Form .div_confirmation {
    gap: 3em;
  }
}

/* Écran PC */
@media (min-width: 1400px) {

  /* Div Form */
  .Form .div_form {
    height: 100%;
  }

  .Form .div_form .InputList {
    gap: 2em;
  }

  .Form .div_form .InputList .Raw_1 {
    gap: 4em;
  }

  .Form .div_form .InputList .Other {
    gap: 2em;
  }

  .Form .div_form .SubmitForm {
    margin-right: 1em;
    margin-bottom: 3em;
  }

  /* Div Confirmation Email */
  .Form .div_confirmation {
    gap: 3em;
  }
}