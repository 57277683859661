/* Commun */
.Triangle {
  position: absolute;
  z-index: -100;

  background-color: var(--main-color);
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {

}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {

}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .Triangle {
    clip-path: polygon(
      0% 0%,
      20% 100%,
      0% 100%
    );

    width: 100%;
    height: 100%;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .Triangle {
    clip-path: polygon(
      0% 0%,
      30% 100%,
      0% 100%
    );

    width: 100%;
    height: 100%;
  }
}
