/* Commun */
.SelectLanguage select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: normal;
  position: relative;
  border: 1px solid var(--secondary-color);
  padding: 0;
  margin: 0;
  border-radius: 3px;
  background-color: #fff;
  background-image: url(../../../../public/img/chevron.svg);
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  color: #000;
  cursor: pointer !important;
}

/* Style pour la flèche du select lorsqu'il est ouvert */
.SelectLanguage select:active,
.SelectLanguage select:focus {
  outline: none;
  box-shadow: none;
}

.SelectLanguage select:focus {
  outline: none;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .SelectLanguage select {
    width: 80px;
    height: 25px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .SelectLanguage select {
    width: 80px;
    height: 25px;
    padding-left: 5px;
    padding-top: 2px;
    background-size: 12px;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .SelectLanguage select {
    width: 80px;
    height: 25px;
    padding-left: 5px;
    padding-top: 2px;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .SelectLanguage select {
    width: 80px;
    height: 25px;
    padding-left: 5px;
    display: flex;
    align-items: center;
  }
}