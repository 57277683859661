.BlocContact {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BlocContact .information .contactList {
  display: flex;
  flex-direction: column;
}

.BlocContact .information .contactList .label{
  font-weight: bold;
}

.BlocContact .information .contactList .form button{
  font-weight: bold;

  background-image: var(--svg-underline);
  background-repeat: no-repeat;

  transition: background-position 0.2s ease-in-out;

  cursor: pointer !important;
}

.BlocContact .information .contactList .form button:hover{
  background-image: var(--svg-underline);
  background-repeat: no-repeat;
  background-position: bottom center;
}

.BlocContact .information .contactList .form button:active{
  transform: translateY(1px);
}

/* Commun */


/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .BlocContact {
    gap: 2em;
    margin: 0 5%;
    flex-direction: column;
  }
  
  .BlocContact .information {
    order: 1;
  }

  .BlocContact .Map {
    order: 2;
  }

  .BlocContact .information h2{
    margin-bottom: 50px;
  }
  
  .BlocContact .information .contactList {
    gap: 1em;
  }

  .BlocContact .information .contactList .form{
    margin-top: 1em;
    font-size: 120%;
  }

  .BlocContact .information .contactList .form button{
    background-position: 0 40px;

    padding-bottom: 5px;
  }

  .BlocContact .svg-underline {
    padding-bottom: 20px;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .BlocContact {
    gap: 2em;
    margin: 0 5%;
    flex-direction: column;
  }
  
  .BlocContact .information {
    order: 1;
  }

  .BlocContact .Map {
    order: 2;
  }

  .BlocContact .information h2{
    margin-bottom: 50px;
  }
  
  .BlocContact .information .contactList {
    gap: 1em;
  }

  .BlocContact .information .contactList .form{
    margin-top: 1em;
    font-size: 120%;
  }

  .BlocContact .information .contactList .form button{
    background-position: 0 40px;

    padding-bottom: 5px;
  }

  .BlocContact .svg-underline {
    padding-bottom: 20px;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .BlocContact {
    gap: 2em;
  }
  
  .BlocContact .information h2{
    margin-bottom: 50px;
  }
  
  .BlocContact .information .contactList {
    gap: 1em;
  }

  .BlocContact .information .contactList .form{
    margin-top: 1em;
    font-size: 120%;
  }

  .BlocContact .information .contactList .form button{
    background-position: 0 40px;

    padding-bottom: 5px;
  }

  .BlocContact .svg-underline {
    padding-bottom: 20px;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .BlocContact {
    gap: 2em;
  }
  
  .BlocContact .information h2{
    margin-bottom: 50px;
  }
  
  .BlocContact .information .contactList {
    gap: 1em;
  }

  .BlocContact .information .contactList .form{
    margin-top: 1em;
    font-size: 120%;
  }

  .BlocContact .information .contactList .form button{
    background-position: 0 40px;

    padding-bottom: 5px;
  }

  .BlocContact .svg-underline {
    padding-bottom: 20px;
  }
}