.BlocHeader {
  display: flex;
  justify-content: center;
}

/* Commun */


/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .BlocHeader {
    padding-top: 2em;
  }

  .BlocHeader img {
    height: 8em;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .BlocHeader {
    padding-top: 2em;
  }

  .BlocHeader img {
    height: 8em;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .BlocHeader {
    padding-top: 1.5em;
  }

  .BlocHeader img {
    height: 8em;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .BlocHeader {
    padding-top: 3em;
  }

  .BlocHeader img {
    height: 8em;
  }
}