@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('../../public/font/Jost-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumGothic';
  src: local('NanumGothic'), url('../../public/font/NanumGothic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumGothicBold';
  src: local('NanumGothicBold'), url('../../public/font/NanumGothic-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Twemoji Country Flags';
  unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067, U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
  src: url('https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill@0.1/dist/TwemojiCountryFlags.woff2') format('woff2');
}

body {
  font-family: var(--user-defined-font), sans-serif;
}

.win {
  font-family: "Twemoji Country Flags", "Jost", sans-serif;
}


body {
  font-family: 'Jost', sans-serif;
}

:root {
  --main-color: #ffd17c;
  --secondary-color: #222;
  --tertiary-color: #000;
  --quaternary-color: #DE6B48;
  --svg-underline: url('../../public/img/underline.svg');
}

.hide {
  display: none !important;
}

h2 {
  font-family: "NanumGothic";
  font-size: 30px;
}

h2.bold {
  font-family: "NanumGothicBold";
}

h3 {
  font-family: "NanumGothic";
  font-size: 24px;
}

p, li {
  font-size: 18px;
}

.svg-underline {
  background-image: var(--svg-underline);
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-bottom: 10px;
}

li:before {
  content: ">";
  font-weight: 800;
  font-family: Montserrat,sans-serif;
  color: var(--main-color);
  padding-right: 10px;
}

a:hover {
  border-bottom: solid 1.5px var(--secondary-color);
}

a:active {
  border-bottom: solid 1.5px #FFF;
}