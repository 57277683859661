/* Commun */
.ButtonShutter {
  position: fixed;
  z-index: 20;
  transition: .4s;
}

.ButtonShutter:active {
  transform: translateY(1px);
}

.ButtonShutter .triangle {
  position: absolute;
  cursor: pointer;
  transform: rotate(45deg);
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: .4s;
}

.ButtonShutter .triangle .project-btn {
  transform: rotate(-45deg);
  position: absolute;
}


.ButtonShutter.close .triangle {
  color: var(--secondary-color);
  background-color: var(--main-color);
}

.ButtonShutter.close:hover .triangle {
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.ButtonShutter.close .triangle span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.ButtonShutter.open .triangle {
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.ButtonShutter.open:hover .triangle {
  color: var(--main-color);
  background-color: var(--tertiary-color);
}

.ButtonShutter.open .triangle svg {
  transform: rotate(45deg);
  position: relative;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {
  .ButtonShutter {
    top: 80px;
  }

  .ButtonShutter .triangle {
    width: 135px;
    height: 135px;
  }

  /* Shutter is closed */
  .ButtonShutter.close {
    right: 25px;
  }

  .ButtonShutter.close .triangle span {
    right: 65px;
    bottom: 4px;
  }

  .ButtonShutter.close .triangle svg {
    width: 15px;
  }

  .ButtonShutter.close .triangle p {
    font-size: 8px;
    font-weight: 500
  }

  /* Shutter is opened */
  .ButtonShutter.open {
    right: 10px;
  }


  .ButtonShutter.open .triangle svg {
    width: 25px;
    right: 270%;
  }
}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {
  .ButtonShutter {
    top: 80px;
  }

  .ButtonShutter .triangle {
    width: 135px;
    height: 135px;
  }

  /* Shutter is closed */
  .ButtonShutter.close {
    right: 50px;
  }

  .ButtonShutter.close .triangle span {
    right: 85%;
    bottom: 10px;
  }

  .ButtonShutter.close .triangle svg {
    width: 25px;
  }

  .ButtonShutter.close .triangle p {
    font-size: 12px;
    font-weight: 500
  }

  /* Shutter is opened */
  .ButtonShutter.open {
    right: 20px;
  }


  .ButtonShutter.open .triangle svg {
    width: 25px;
  
    right: 250%;
  }
}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {
  .ButtonShutter {
    top: 80px;
  }

  .ButtonShutter .triangle {
    width: 135px;
    height: 135px;
  }

  /* Shutter is closed */
  .ButtonShutter.close {
    right: 50px;
  }

  .ButtonShutter.close .triangle span {
    right: 85%;
    bottom: 10px;
  }

  .ButtonShutter.close .triangle svg {
    width: 25px;
  }

  .ButtonShutter.close .triangle p {
    font-size: 12px;
    font-weight: 500
  }

  /* Shutter is opened */
  .ButtonShutter.open {
    right: 20px;
  }


  .ButtonShutter.open .triangle svg {
    width: 25px;
  
    right: 250%;
  }
}

/* Écran PC */
@media (min-width: 1400px) {
  .ButtonShutter {
    top: 80px;
  }

  .ButtonShutter .triangle {
    width: 135px;
    height: 135px;
  }

  /* Shutter is closed */
  .ButtonShutter.close {
    right: 50px;
  }

  .ButtonShutter.close .triangle span {
    right: 85%;
    bottom: 10px;
  }

  .ButtonShutter.close .triangle svg {
    width: 25px;
  }

  .ButtonShutter.close .triangle p {
    font-size: 12px;
    font-weight: 500
  }

  /* Shutter is opened */
  .ButtonShutter.open {
    right: 20px;
  }


  .ButtonShutter.open .triangle svg {
    width: 25px;
  
    right: 250%;
  }
}