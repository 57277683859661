/* Commun */
/* Classic */
.Button button {
  border: 2px solid var(--secondary-color);
  color: var(--secondary-color);
  padding: 0.5em 1.5em;
  transition: .4s;  
}

.Button button:disabled {
  opacity: 0.5 !important;
}

.Button:not(.disabled) button:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

/* Alter */
.Button.alter button {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.Button.alter button:hover {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  color: var(--secondary-color);
}

/* Dark */
.Button.dark button {
  border: 2px solid var(--main-color);
  background-color: transparent;
  color: var(--main-color);
}

.Button.dark button:hover {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  color: var(--secondary-color);
}

.Button:not(.disabled):active button {
  transform: translateY(-2%);
  box-shadow: -0.1em 0.2em 0.2em rgba(91, 91, 91, 0.1);
}

.Button:not(.disabled) button {
  cursor: pointer !important;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 640px) {

}

/* Tablette */
@media (min-width: 641px) and (max-width: 1007px) {

}

/* Petit écran PC */
@media (min-width: 1008px) and (max-width: 1399px) {

}

/* Écran PC */
@media (min-width: 1400px) {

}